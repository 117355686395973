@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=532073d1-397b-453d-916b-fc4477b3f91a&fontids=1473732,1473737,1473647,1473652,");

@font-face {
    font-family: "Meta Pro Serif";
    font-weight: 350;
    src: url("1473647/4e5f5a70-c3cf-4ab1-a72b-6fa42aa30e27.woff2") format("woff2"),
    url("1473647/33dfbafa-09c8-464c-b546-b16872b305a4.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Meta Pro Serif";
    font-weight: 500;
    src: url("1473652/9316d577-15b5-47fa-91cf-f430301f839c.woff2") format("woff2"),
    url("1473652/c76ac14c-40cb-4dad-bc73-3aa9b049882f.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Meta Pro";
    font-weight: 350;
    src: url("1473732/cf9a7195-d944-495d-bce1-be91ce5f74f2.woff2") format("woff2"),
    url("1473732/fa36a16a-d2ff-4e4b-9dbe-3c517f881625.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Meta Pro";
    font-weight: 500;
    src: url("1473737/5361a8da-2038-4b9f-b58e-060e60605608.woff2") format("woff2"),
    url("1473737/407aae8b-c685-4c9e-8484-59ce8648be84.woff") format("woff");
    font-display: swap;
}
